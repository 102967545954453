import {
  CSS3,
  Express,
  HTML5,
  Javascript,
  Mongodb,
  Nodejs,
  Reactjs,
  admin,
  fintech,
  data,
  portf,
  biz,
  
} from "./assets";

export const projectStats = [
  {
    name: "Total Projects",
    value: "200+",
  },
  {
    name: "Website Design",
    value: "190+",
  },
  {
    name: "Projects Managed",
    value: "+3",
  },
  {
    name: "Clients worldwide",
    value: "+86",
  },
];

export const tools = [
  {
    name: "MongoDB",
    icon: Mongodb,
  },
  {
    name: "Express",
    icon: Express,
  },
  {
    name: "React",
    icon: Reactjs,
  },
  {
    name: "NodeJs",
    icon: Nodejs,
  },

];

export const skills = [
  {
    name: "ReactJs",
    value: 68,
    icon: Reactjs,
  },
  {
    name: "NodeJs",
    value: 50,
    icon: Nodejs,
  },
  
  {
    name: "MongoDB",
    value: 60,
    icon: Mongodb,
  },
  {
    name: "TAILWIND CSS",
    value: 58,
    icon: CSS3,
  },
  {
    name: "HTML5",
    value: 98,
    icon: HTML5,
  },
  {
    name: "JavaScript",
    value: 60,
    icon: Javascript,
  },
 
];

export const projects = [
  {
    id: 1,
    title: "Admin Dashboard",
    cat: "Web Design",
    url: "",
    img: admin,
  },
  {
    id: 2,
    title: "Portfolio Website",
    cat: "Web Design",
    url: "",
    img: portf,
  },
 
  {
    id: 4,
    title: "Sendcheap Fintech",
    cat: "Product Manager",
    url: "",
    img: fintech,
  },
  {
    id: 5,
    title: "Data Management",
    cat: "Web App",
    url: "",
    img: data,
  },
  {
    id: 6,
    title: "Business/SaaS",
    cat: "Web App",
    url: "",
    img: biz,
  },
  
];

export const softskills = [
  "Time management",
  "Communication",
  "Empathy",
  "Teamwork",
  "Helpfulness",
  "Creativity",
  "Self-learning",
  "Accountability",
  "Problem solving",
  "Open-mindedness",
];
